import { DIGITAL_RANKING } from "../types";

export function digitalRankingTop100(slug) {
    return{
        type:DIGITAL_RANKING.FETCH,
        payload:slug
    }
}
export function digitalRankingTop100Success(data) {
    return {
        type: DIGITAL_RANKING.SUCCESS,
        payload: data
    }
}
export function digitalRankingTop100Error(message) {
    return {
        type: DIGITAL_RANKING.ERROR,
        payload: message
    }
}
export function digitalRankingTop100Reset() {
    return {
        type: DIGITAL_RANKING.RESET
    }
}
const digitalRankingTop100Action = {
    digitalRankingTop100,
    digitalRankingTop100Success,
    digitalRankingTop100Error,
    digitalRankingTop100Reset
}
export default digitalRankingTop100Action