import React from 'react'

const Error404 = () => {
    return (
        <div className="methodologySection align-items-center mb-180 mt-100">
            <div className="row" style={{ "width": "100%" }}>
                <h6 className="text-center mb-2">Oops!</h6>
                <p>404 - PAGE NOT FOUND.</p>
            </div>
        </div>
    )
}

export default Error404