
import { apiTypeCreater } from "../utils";

export const BRAND_CATEGORIES = apiTypeCreater("BRAND_CATEGORIES")
export const DIGITAL_RANKING = apiTypeCreater("DIGITAL_RANKING")
export const BLOGS = apiTypeCreater("BLOGS")
export const CATEGORY_BLOGS = apiTypeCreater("CATEGORY_BLOGS")
export const SINGLE_BLOGS = apiTypeCreater("SINGLE_BLOGS")
export const IN_MEDIA = apiTypeCreater("IN_MEDIA")
export const BANNER = apiTypeCreater("BANNER")
export const RECENT_POST = apiTypeCreater("RECENT_POST")
export const TOP_BRANDS = apiTypeCreater("TOP_BRANDS")
export const SEARCHDGRANK = apiTypeCreater("SEARCHDGRANK")