import { combineReducers } from "redux";

import brand_categories from "./brand_categories.reducer";
import blogs from "./blogs.reducer";
import categoryBlog from "./category_blogs.reducer";
import singleBlogReducer from "./single_blog.reducer";
import inMediaReducer from "./inMedia.reducer";
import bannerReducer from "./banner.reducer";
import recentPostReducer from "./recentPost.reducer";
import topBrandsReducer from "./topBrands.reducer";
import digitalRankingReducer from "./digitalRankingTop100.reducer";
import searchDgRankReducer from "./search_dg_rank.reducer"
const resume = combineReducers({
    brand_categories: brand_categories,
    blogs:blogs,
    categoryBlog:categoryBlog,
    singleBlog:singleBlogReducer,
    inMedia:inMediaReducer,
    banner:bannerReducer,
    recentPost:recentPostReducer,
    topBrands:topBrandsReducer,
    digitalRanking:digitalRankingReducer,
    searchDgRank:searchDgRankReducer
})
export default resume