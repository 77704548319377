import React from "react";

const Loder = () => {
  return (
    <>
      <section className="sectionNewBoxOne pt-3">
        <div className="container tab-content registerTabAreaPanel ">
          <div className="tab-pane active" id="homeTabStap0" role="tabpanel">
            <p className="text-center" />
            <div className="loader2">
              <div className="loading-area">
                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="
      M 100, 100
      m -75, 0
      a 75,75 0 1,0 150,0
      a 75,75 0 1,0 -150,0"
                    id="path-circle"
                    fill="none"
                  />
                  <text>
                    <textPath href="#path-circle">
                      Digital Ranking | Social Media Ranking | Monitoring |
                    </textPath>
                  </text>
                </svg>
                <div className="loader1Img">
                  <img
                    src="https://checkbrand.online/frontend/images/logo_icon.png"
                    alt="logo"
                  />
                </div>
                <div className="circle" />
                <div className="circle-small" />
                <div className="circle-big" />
                <div className="circle-inner-inner" />
                <div className="circle-inner" />
              </div>
            </div>
            <p />
          </div>
        </div>
      </section>
    </>
  );
};

export default Loder;
