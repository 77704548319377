import { all } from "redux-saga/effects";
import blogs from "./blogs.saga";
import brand_categories from "./brand_categories.saga";
import categoryBlogsSaga from "./category_blogs.saga";
import InMediaSaga from "./inMedia.saga";
import singleBlogSaga from "./single_blog.saga";
import bannerSaga from "./banner.saga";
import recentPostSaga from "./recentPost.saga";
import topBrands from "./topBrands.saga";
import  digitalRankingTop100Saga  from "./digitalRankingTop100.saga";
import  SearchDgRankSaga  from "./search_dg_rank.saga";
export default function* rootSaga() {
  yield all([
    brand_categories(),
    blogs(),
    categoryBlogsSaga(),
    singleBlogSaga(),
    InMediaSaga(),
    bannerSaga(),
    recentPostSaga(),
    topBrands(),
    digitalRankingTop100Saga(),
    SearchDgRankSaga()
  ]);
}
