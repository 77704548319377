import { call, put, takeEvery } from "redux-saga/effects";
import { searchDgRankSuccess, searchDgRankError } from '../actions/search_dg_rank.action';
import { getSearchDgRankApi } from '../services/api.service';
import { SEARCHDGRANK } from "../types";

function* fetchSearchDgRank({ payload }) {
    try {
        const searchDgRank = yield call(getSearchDgRankApi, payload);
        yield put(searchDgRankSuccess(searchDgRank))
    } catch (error) {
        yield put(searchDgRankError(error))
    }
}

export default function* SearchDgRankSaga() {
    yield takeEvery(SEARCHDGRANK.FETCH, fetchSearchDgRank)
}