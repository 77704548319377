import { call, put, takeEvery } from 'redux-saga/effects';
import {blogSuccess,blogError} from '../actions/blogs.action';
import { getBlogsApi } from '../services/api.service';
import { BLOGS } from '../types';

function* fetchBlogs() {
    try {
        const categories = yield call(getBlogsApi);
        yield put(blogSuccess(categories));
      } catch (e) {
        yield put(blogError(e));
      }
}

export default function* BlogSaga() {
    yield takeEvery(BLOGS.FETCH, fetchBlogs)
}