import { TOP_BRANDS } from "../types";

export function topBrands(slug) {
    return{
        type:TOP_BRANDS.FETCH,
        payload:slug
    }
}
export function topBrandsSuccess(data) {
    return {
        type: TOP_BRANDS.SUCCESS,
        payload: data
    }
}
export function topBrandsError(message) {
    return {
        type: TOP_BRANDS.ERROR,
        payload: message
    }
}
export function topBrandsReset() {
    return {
        type: TOP_BRANDS.RESET
    }
}
const topBrandsAction = {
    topBrands,
    topBrandsSuccess,
    topBrandsError,
    topBrandsReset
}
export default topBrandsAction