import { call, takeEvery, put } from "redux-saga/effects";
import { InMediaSuccess, InMediaError } from "../actions/inMedia.action";
import { IN_MEDIA } from "../types/index";
import { getInMediaApi } from "../services/api.service";
function* fetchInMedia({ payload }) {
  try {
    const inMedia = yield call(getInMediaApi,payload);
    yield put(InMediaSuccess(inMedia))
  } catch (error) {
    yield put(InMediaError(error))
  }
}
export default function* InMediaSaga (){
    yield takeEvery(IN_MEDIA.FETCH,fetchInMedia)
}