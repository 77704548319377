import { call, put, takeEvery } from 'redux-saga/effects';
import {categoryBlogsSuccess,categoryBlogsError} from '../actions/category_blogs.action';
import { getCategoryBlogsApi } from '../services/api.service';
import { CATEGORY_BLOGS } from '../types';

function* fetchCategoryBlogs({payload}) {
    try {
        const categories = yield call(getCategoryBlogsApi,payload);
        yield put(categoryBlogsSuccess(categories));
      } catch (e) {
        yield put(categoryBlogsError(e));
      }
}

export default function* categoryBlogsSaga() {
    yield takeEvery(CATEGORY_BLOGS.FETCH, fetchCategoryBlogs)
}