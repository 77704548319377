import { SINGLE_BLOGS } from "../types";

const initialState = { data: null, loading: false, error: null };

const singleBlogReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case SINGLE_BLOGS.FETCH: {
            return { ...state, loading: true }
        }
        case SINGLE_BLOGS.SUCCESS:{
            const data = payload
            return {...state,loading:false,data}
        }
        case SINGLE_BLOGS.ERROR:{
            const message = payload
            return {...state,loading:false,message}
        }
        case SINGLE_BLOGS.RESET:{
            return {...initialState}
        }
        default:
            return state
    }

}

export default singleBlogReducer