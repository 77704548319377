import { BANNER } from '../types';
const initialState = { data: null, loading: false, error: null };
const bannerReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case BANNER.FETCH: {
            return { ...state, loading: true }
        }
        case BANNER.SUCCESS: {
            const data = payload;
            return { ...state, loading: false, data }
        }
        case BANNER.ERROR: {
            const message = payload
            return { ...state, loading: false, message }
        }
        case BANNER.RESET: {
            return {...initialState}
        }
        default:
            return state
    }
}

export default bannerReducer