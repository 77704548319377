import { SINGLE_BLOGS } from '../types/index'

export function singleBlog(slug) {
    return {
        type: SINGLE_BLOGS.FETCH,
        payload: slug
    }
}
export function singleBlogSuccess(data) {
    return {
        type: SINGLE_BLOGS.SUCCESS,
        payload: data
    }
}
export function singleBlogError(message) {
    return{
        type:SINGLE_BLOGS.ERROR,
        payload:message
    }
}
export function singleBlogReset() {
    return{
        type:SINGLE_BLOGS.RESET
    }
}
const singleBlogActions={
    singleBlog,
    singleBlogSuccess,
    singleBlogError,
    singleBlogReset
}
export default singleBlogActions