import { call, put, takeEvery } from 'redux-saga/effects';
import {bannerSuccess,bannerError} from '../actions/banner.action';
import { getBannerApi } from '../services/api.service';
import { BANNER } from '../types';

function* fetchBanners() {
    try {
        const banners = yield call(getBannerApi);
        yield put(bannerSuccess(banners));
      } catch (e) {
        yield put(bannerError(e));
      }
}

export default function* BlogSaga() {
    yield takeEvery(BANNER.FETCH, fetchBanners)
}