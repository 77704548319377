import { SEARCHDGRANK } from '../types/index'

export function searchDgRank(slug) {
    return {
        type: SEARCHDGRANK.FETCH,
        payload: slug
    }
}
export function searchDgRankSuccess(data) {
    return {
        type: SEARCHDGRANK.SUCCESS,
        payload: data
    }
}
export function searchDgRankError(message) {
    return{
        type:SEARCHDGRANK.ERROR,
        payload:message
    }
}
export function searchDgRankReset() {
    return{
        type:SEARCHDGRANK.RESET
    }
}
const searchDgRankActions={
    searchDgRank,
    searchDgRankSuccess,
    searchDgRankError,
    searchDgRankReset
}
export default searchDgRankActions