import { BLOGS } from "../types";

export function blogs() {
    return{
        type:BLOGS.FETCH
    }
}
export function blogSuccess(data) {
    return {
        type: BLOGS.SUCCESS,
        payload: data
    }
}
export function blogError(message) {
    return {
        type: BLOGS.ERROR,
        payload: message
    }
}
export function blogReset() {
    return {
        type: BLOGS.RESET
    }
}
const blogAction = {
    blogs,
    blogSuccess,
    blogError,
    blogReset
}
export default blogAction