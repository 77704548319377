import { IN_MEDIA } from "../types/index";

export function InMedia(slug) {
  return {
    type: IN_MEDIA.FETCH,
    payload: slug,
  };
}
export function InMediaSuccess(data) {
  return {
    type: IN_MEDIA.SUCCESS,
    payload: data,
  };
}
export function InMediaError(message) {
  return {
    type: IN_MEDIA.ERROR,
    payload: message,
  };
}
export function InMediaReset() {
  return {
    type: IN_MEDIA.RESET
  };
}
const InMediaAction = {
    InMedia,
    InMediaSuccess,
    InMediaError,
    InMediaReset
}
export default InMediaAction;