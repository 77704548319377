import { call, put, takeEvery } from 'redux-saga/effects';
import {recentPostSuccess,recentPostError} from '../actions/recentPost.action';
import { getRecentPostApi } from '../services/api.service';
import { RECENT_POST } from '../types';

function* fetchRecentPost() {
    try {
        const recentPost = yield call(getRecentPostApi);
        yield put(recentPostSuccess(recentPost));
      } catch (e) {
        yield put(recentPostError(e));
      }
}

export default function* BlogSaga() {
    yield takeEvery(RECENT_POST.FETCH, fetchRecentPost)
}