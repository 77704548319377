import { CATEGORY_BLOGS } from "../types";

export function categoryBlogs(slug) {
    return{
        type:CATEGORY_BLOGS.FETCH,
        payload:slug
    }
}
export function categoryBlogsSuccess(data) {
    return {
        type: CATEGORY_BLOGS.SUCCESS,
        payload: data
    }
}
export function categoryBlogsError(message) {
    return {
        type: CATEGORY_BLOGS.ERROR,
        payload: message
    }
}
export function categoryBlogsReset() {
    return {
        type: CATEGORY_BLOGS.RESET
    }
}
const categoryBlogsAction = {
    categoryBlogs,
    categoryBlogsSuccess,
    categoryBlogsError,
    categoryBlogsReset
}
export default categoryBlogsAction