import axios from "axios";

export const getBrandCategoriesApi = async () => {
  return await axios({
    method: "GET",
    url: `https://checkbrand.online/api/brand_categories`,
  })
    .then((responce) => {
      return responce.data;
    })
    .catch((error) => {
      if (error) {
        return error;
      }
    });
};
export const getDigitalRankingApi = async () => {
  return await axios({
    method: "GET",
    url: `https://checkbrand.online/api/brand_categories`,
  })
    .then((responce) => {
      return responce.data;
    })
    .catch((error) => {
      if (error) {
        return error;
      }
    });
};
export const getBlogsApi = async () => {
  return await axios({
    method: "GET",
    url: `https://checkbrand.online/api/blogs_json`,
  })
    .then((responce) => {
      return responce.data;
    })
    .catch((error) => {
      if (error) {
        return error;
      }
    });
};
export const getCategoryBlogsApi = async (slug) => {
  return await axios({
    method: "GET",
    url: `https://checkbrand.online/api/category_blogs/${slug}`,
  })
    .then((responce) => {
      return responce.data;
    })
    .catch((error) => {
      if (error) {
        return error;
      }
    });
};
export const getSingleBlogsApi = async (slug) => {

  return await axios({
    method: "GET",
    url: `https://checkbrand.online/api/blog_detail/${slug}`,
  })
    .then((responce) => {
      return responce.data;
    })
    .catch((error) => {
      if (error) {
        return error;
      }
    });
};
export const getInMediaApi = async (slug) => {
  return await axios({
    method: "GET",
    url:`${`https://checkbrand.online/api/in-media-api?date=${slug.date&&slug.date}&search=${slug.search&&slug.search}&channel_slug=${slug.id&&slug.id}`}`
  })
    .then((responce) => {
      return responce.data;
    })
    .catch((error) => {
      if (error) {
        return error;
      }
    });
};
export const getBannerApi = async () => {
  return await axios({
    method: "GET",
    url:`${`https://checkbrand.online/api/banners`}`
  })
    .then((responce) => {
      return responce.data;
    })
    .catch((error) => {
      if (error) {
        return error;
      }
    });
};
export const getRecentPostApi = async () => {
  return await axios({
    method: "GET",
    url:`${`https://checkbrand.online/api/recent_posts`}`
  })
    .then((responce) => {
      return responce.data;
    })
    .catch((error) => {
      if (error) {
        return error;
      }
    });
};
export const getTopBrandsApi = async (slug) => {
  return await axios({
    method: "GET",
    url:`${`https://checkbrand.online/api/top-brands-ajax/${slug}?limit=8`}`
  })
    .then((responce) => {
      return responce.data;
    })
    .catch((error) => {
      if (error) {
        return error;
      }
    });
};
export const getDigitalRankingTop100Api = async (slug) => {
  return await axios({
    method: "GET",
    url:`${`https://checkbrand.online/api/top-brands-ajax/${slug}?limit=100`}`
  })
    .then((responce) => {
      return responce.data;
    })
    .catch((error) => {
      if (error) {
        return error;
      }
    });
};
export const getSearchDgRankApi = async (slug) => {
  return await axios({
    method: "GET",
    url:`${`https://checkbrand.online/api/top-brands-ajax/${slug}?limit=100`}`
  })
    .then((responce) => {
      return responce.data;
    })
    .catch((error) => {
      if (error) {
        return error;
      }
    });
};

// export const allCategoriesApi = async () => {
//   return await axios
//     .get("https://checkbrand.online/api/brand_categories")
//     .then((response) =>response.data)
//     .catch((err) => Promise.reject(err.message))
//     .then((res) => res);
// };
