import { call, put, takeEvery } from "redux-saga/effects";
import { topBrandsSuccess, topBrandsError } from '../actions/topBrands.action';
import { getTopBrandsApi } from '../services/api.service';
import { TOP_BRANDS } from "../types";

function* fetchTopBrands({ payload }) {
    try {
        const topBrands = yield call(getTopBrandsApi, payload);
        yield put(topBrandsSuccess(topBrands))
    } catch (error) {
        yield put(topBrandsError(error))
    }
}

export default function* topBrandsSaga() {
    yield takeEvery(TOP_BRANDS.FETCH, fetchTopBrands)
}