import { call, put, takeEvery } from "redux-saga/effects";
import { singleBlogSuccess, singleBlogError } from '../actions/single_blog.action';
import { getSingleBlogsApi } from '../services/api.service';
import { SINGLE_BLOGS } from "../types";

function* fetchSingleBlog({ payload }) {
    try {
        const singleBlog = yield call(getSingleBlogsApi, payload);
        yield put(singleBlogSuccess(singleBlog))
    } catch (error) {
        yield put(singleBlogError(error))
    }
}

export default function* singleBlogSaga() {
    yield takeEvery(SINGLE_BLOGS.FETCH, fetchSingleBlog)
}