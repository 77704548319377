import { RECENT_POST } from "../types";

export function recentPost() {
    return{
        type:RECENT_POST.FETCH
    }
}
export function recentPostSuccess(data) {
    return {
        type: RECENT_POST.SUCCESS,
        payload: data
    }
}
export function recentPostError(message) {
    return {
        type: RECENT_POST.ERROR,
        payload: message
    }
}
export function recentPostReset() {
    return {
        type: RECENT_POST.RESET
    }
}
const recentPostAction = {
    recentPost,
    recentPostSuccess,
    recentPostError,
    recentPostReset
}
export default recentPostAction