import Error404 from "./components/common/Error404";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Faq from "./views/pages/faq/Faq";
import GoToTop from "./components/common/GoToTop";
import Loder from "./components/common/Loder";
const MediaMonitioring = React.lazy(() =>
  import("./views/pages/media-monitioring")
);
const SentimentAnalysis = React.lazy(() =>
  import("./views/pages/sentiment-analysis")
);
const Authentication = React.lazy(() =>
  import("./views/pages/modals/Authentication")
);
const RegisterBrand = React.lazy(() =>
  import("./views/pages/modals/RegisterBrand")
);
const Category = React.lazy(() =>
  import("./views/pages/digitalRanking/category")
);
const SearchModalPopUp = React.lazy(() =>
  import("./views/pages/modals/Search")
);
const SocialListening = React.lazy(() =>
  import("./views/pages/social-listening")
);
const ProductLaunch = React.lazy(() => import("./views/pages/product-launch"));
const InMediaCategory = React.lazy(() =>
  import("./views/pages/in-media/media-type")
);
const InMedia = React.lazy(() => import("./views/pages/in-media"));
const CampaignManagement = React.lazy(() =>
  import("./views/pages/campaign-management")
);
const PrReporting = React.lazy(() => import("./views/pages/pr-reporting"));
const BrandingCampaign = React.lazy(() =>
  import("./views/pages/branding-campaign")
);
const BrandManagement = React.lazy(() =>
  import("./views/pages/brand-management")
);
const SalesIntelligence = React.lazy(() =>
  import("./views/pages/sales-intelligence")
);
const MarketIntelligence = React.lazy(() =>
  import("./views/pages/market-intelligence")
);
const TrendAnalysis = React.lazy(() => import("./views/pages/trend-analysis"));
const RealTimeAlerting = React.lazy(() =>
  import("./views/pages/real-time-alerting")
);
const CompetitiveIntelligence = React.lazy(() =>
  import("./views/pages/competitive-intelligence")
);
const RealTimeMediaMonitoring = React.lazy(() =>
  import("./views/pages/real-time-media-monitoring")
);
const SocialMediaReports = React.lazy(() =>
  import("./views/pages/social-media-reports")
);
const MonitoringMarketingTrends = React.lazy(() =>
  import("./views/pages/monitoring-marketing-trends")
);
const ConsumerInsights = React.lazy(() =>
  import("./views/pages/consumer-insights")
);
const OnlineReputationManagement = React.lazy(() =>
  import("./views/pages/orm")
);
const MarketResearch = React.lazy(() =>
  import("./views/pages/market-research")
);
const DigitalRanking = React.lazy(() => import("./views/pages/digitalRanking"));
const RealTimeAnalytics = React.lazy(() =>
  import("./views/pages/real-time-analytics")
);
const ShareOfVoice = React.lazy(() => import("./views/pages/share-of-voice"));
const BrandProfile = React.lazy(() => import("./views/pages/brand_profile"));
const Footer = React.lazy(() => import("./components/common/Footer"));
const Header = React.lazy(() => import("./components/common/Header"));
const Contact = React.lazy(() => import("./views/pages/contact"));
const Profile = React.lazy(() => import("./views/pages/profile"));
const Trends = React.lazy(() => import("./views/pages/trends"));
const Search = React.lazy(() => import("./views/pages/search"));
const Nav = React.lazy(() => import("./components/common/Nav"));
const Blogs = React.lazy(() => import("./views/pages/blogs"));
const Home = React.lazy(() => import("./views/pages/home"));
const DigitalSurvey = React.lazy(() => import("./views/pages/digital-survey"));
const DigitalResearch = React.lazy(() =>
  import("./views/pages/digital-research")
);
const Terms = React.lazy(() => import("./views/pages/terms"));
const About = React.lazy(() => import("./views/pages/about"));
const Privacy = React.lazy(() => import("./views/pages/privacy"));
const CookiePolicy = React.lazy(() => import("./views/pages/cookie-policy"));
const Disclaimer = React.lazy(() => import("./views/pages/disclaimer"));
const Fortune500 = React.lazy(() => import("./views/pages/fortune500"));

function App() {
  const [sideToggle, setSideToggle] = useState(false);

  useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
    };
    function scrollFunction() {
      if (
        document.body.scrollTop > 150 ||
        document.documentElement.scrollTop > 150
      ) {
        let header = document.getElementById("header");
        header.classList.add("hide");
        let nav = document.getElementById("my-nav1");
        nav.classList.add("fixed-menu");
      } else {
        document.getElementById("header").classList.remove("hide");
        document.getElementById("my-nav1").classList.remove("fixed-menu");
      }
    }
  }, []);
  const handleOnToggle = () => {
    setSideToggle(!sideToggle);
  };

  return (
    <>
      <BrowserRouter>
        <React.Suspense fallback={<Loder />}>
          <Header handleOnToggle={handleOnToggle} sideToggle={sideToggle} />
          <Nav sideToggle={sideToggle} handleOnToggle={handleOnToggle} />
          <Authentication />
          <RegisterBrand />
          <SearchModalPopUp />
          <Routes>
            <Route
              path="/"
              element={
                <Home sideToggle={sideToggle} handleOnToggle={handleOnToggle} />
              }
            />
            <Route
              exact
              path="/online-reputation-management"
              element={<OnlineReputationManagement />}
            />
            <Route
              exact
              path="/monitoring-marketing-trends"
              element={<MonitoringMarketingTrends />}
            />
            <Route
              exact
              path="/real-time-media-monitoring"
              element={<RealTimeMediaMonitoring />}
            />
            <Route
              exact
              path="/competitive-intelligence"
              element={<CompetitiveIntelligence />}
            />
            <Route
              exact
              path="/social-media-reports"
              element={<SocialMediaReports />}
            />
            <Route
              exact
              path="/campaign-management"
              element={<CampaignManagement />}
            />
            <Route
              exact
              path="/market-intelligence"
              element={<MarketIntelligence />}
            />
            <Route
              exact
              path="/real-time-analytics"
              element={<RealTimeAnalytics />}
            />
            <Route
              exact
              path="/sales-intelligence"
              element={<SalesIntelligence />}
            />
            <Route
              exact
              path="/real-time-alerting"
              element={<RealTimeAlerting />}
            />
            <Route
              exact
              path="/sentiment-analysis"
              element={<SentimentAnalysis />}
            />
            <Route
              exact
              path="/consumer-insights"
              element={<ConsumerInsights />}
            />
            <Route
              exact
              path="/branding-campaign"
              element={<BrandingCampaign />}
            />
            <Route
              exact
              path="/media-monitioring"
              element={<MediaMonitioring />}
            />
            <Route
              exact
              path="/brand-management"
              element={<BrandManagement />}
            />
            <Route
              exact
              path="/social-listening"
              element={<SocialListening />}
            />
            <Route
              exact
              path="/digital-research"
              element={<DigitalResearch />}
            />
            <Route exact path="/digital-ranking" element={<DigitalRanking />} />
            <Route exact path="/market-research" element={<MarketResearch />} />
            <Route exact path="/product-launch" element={<ProductLaunch />} />
            <Route exact path="/viral-trending-blogs/*" element={<Blogs />} />
            <Route exact path="/trend-analysis" element={<TrendAnalysis />} />
            <Route exact path="/digital-survey" element={<DigitalSurvey />} />
            <Route exact path="/share-of-voice" element={<ShareOfVoice />} />
            <Route exact path="/cookie-policy" element={<CookiePolicy />} />
            <Route exact path="/pr-reporting" element={<PrReporting />} />
            <Route exact path="/disclaimer" element={<Disclaimer />} />
            <Route exact path="/in-media" element={<InMedia />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/search" element={<Search />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/faq" element={<Faq />} />
            <Route exact path="/fortune500" element={<Fortune500 />} />
            {/* digital-ranking (category) */}
            <Route exact path="/category/*" element={<Category />} />
            {/* In-media-category (category) */}
            <Route exact path="/in-media/*" element={<InMediaCategory />} />
            {/* brand-category (category) */}
            <Route path="/brand/*" element={<BrandProfile />} />
            <Route exact path="/trends/*" element={<Trends />} />
            <Route exact path="/*" element={<Error404 />} />
          </Routes>
          <GoToTop />
          <Footer />
        </React.Suspense>
      </BrowserRouter>
    </>
  );
}

export default React.memo(App);
