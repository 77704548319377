import { BANNER } from "../types";

export function banner() {
    return{
        type:BANNER.FETCH
    }
}
export function bannerSuccess(data) {
    return {
        type: BANNER.SUCCESS,
        payload: data
    }
}
export function bannerError(message) {
    return {
        type: BANNER.ERROR,
        payload: message
    }
}
export function bannerReset() {
    return {
        type: BANNER.RESET
    }
}
const bannerAction = {
    banner,
    bannerSuccess,
    bannerError,
    bannerReset
}
export default bannerAction