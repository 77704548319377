import { BRAND_CATEGORIES } from "../types";

export function categorys() {
    
    return{
        type:BRAND_CATEGORIES.FETCH
    }
}
export function categorySuccess(data) {
    return {
        type: BRAND_CATEGORIES.SUCCESS,
        payload: data
    }
}
export function categoryError(message) {
    return {
        type: BRAND_CATEGORIES.ERROR,
        payload: message
    }
}
export function categoryReset() {
    return {
        type: BRAND_CATEGORIES.RESET
    }
}
const categoryAction = {
    categorys,
    categorySuccess,
    categoryError,
    categoryReset
}
export default categoryAction