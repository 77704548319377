import { IN_MEDIA } from "../types/index";

const initialState = { data: null, loading: false, error: null };

const inMediaReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case IN_MEDIA.FETCH: {
      return { ...state, loading: true };
    }
    case IN_MEDIA.SUCCESS: {
      const data = payload;
      return { ...state, loading: false, data };
    }
    case IN_MEDIA.ERROR: {
      const message = payload;
      return { ...state, loading: false, message };
    }
    case IN_MEDIA.RESET: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
};

export default inMediaReducer;
