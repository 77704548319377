import { SEARCHDGRANK } from "../types";

const initialState = { data: null, loading: false, error: null };

const searchDgRankReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case SEARCHDGRANK.FETCH: {
            return { ...state, loading: true }
        }
        case SEARCHDGRANK.SUCCESS:{
            const data = payload
            return {...state,loading:false,data}
        }
        case SEARCHDGRANK.ERROR:{
            const message = payload
            return {...state,loading:false,message}
        }
        case SEARCHDGRANK.RESET:{
            return {...initialState}
        }
        default:
            return state
    }

}

export default searchDgRankReducer