import { call, put, takeEvery } from "redux-saga/effects";
import {
  digitalRankingTop100Success,
  digitalRankingTop100Error,
} from "../actions/digitalRankingTop100.action";
import { getDigitalRankingTop100Api } from "../services/api.service";
import { DIGITAL_RANKING } from "../types";

function* fetchdigitalRankingTop100({ payload }) {
  try {
    const categories = yield call(getDigitalRankingTop100Api, payload);
    yield put(digitalRankingTop100Success(categories));
  } catch (e) {
    yield put(digitalRankingTop100Error(e));
  }
}

export default function* digitalRankingTop100Saga() {
  yield takeEvery(DIGITAL_RANKING.FETCH, fetchdigitalRankingTop100);
}
