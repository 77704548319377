import React, { useEffect, useRef } from "react";
import $ from "jquery";
const Faq = () => {
  const effectRun = useRef(false);
  useEffect(() => {
    if (effectRun.current === false) {
      $(document).ready(function () {
        $(".heading").click(function () {
          $(this).siblings(".text-light").toggle("fast");
        });

        $("#searcher").on("keypress click input", function () {
          var val = $(this).val();
          if (val.length) {
            $(".roadmaps .faq_content").each(function () {
              let description = $.trim(
                $(this)
                  .html()
                  .replace(/(<([^>]+)>)/gi, "")
              );
              if (description.toLowerCase().indexOf(val.toLowerCase()) > -1) {
                $(this).parent().parent().removeClass("hide").addClass("show");
                $(this).find("p.text-light").css("display", "block");
              } else {
                $(this).parent().parent().removeClass("show").addClass("hide");
                $(this).find("p.text-light").css("display", "none");
              }
            });
          }
        });
      });
    }
    return () => {
      effectRun.current = true;
    };
  }, []);

  return (
    <>
      <section className="contact-address-area mb-5 mt-4 ">
        <div className="container">
          <div className="row newfaqsection">
            <div className="col-12 col-lg-12 trailbox_Accord">
              <div className="faqSearchBox">
                <h3 style={{ marginBottom: "40px" }}>
                  Frequently Asked Questions
                </h3>
                <div id="faqSearch_form">
                  <div className="input-group">
                    <input
                      type="search"
                      autoComplete="off"
                      className="form-control"
                      name="searcher"
                      id="searcher"
                      placeholder="Search Question..."
                    />
                  </div>
                </div>
              </div>
              <div className="roadmaps text-white">
                <div className="row gy-3 mb-5">
                  <div className="col-md-6">
                    <div className="step-pr shadow mx-auto ms-md-auto me-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        01
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center text-md-start ms-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q1 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        HOW ARE IMPRESSIONS AND REACH CALCULATED IN CHECKBRAND?{" "}
                      </h2>
                      <p className="text-light">
                        Impressions (the impact) stands for the potential number
                        of times somebody could have seen the hashtag. Whereas
                        the reach stands for the potential number of users that
                        could have seen the hashtag. The impact is calculated by
                        multiplying the tweets a user has sent by the number of
                        followers they have. The reach is calculated by adding
                        the number of followers every account in the report has.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 mb-5">
                  <div className="col-md-6 order-1 order-md-2">
                    <div className="step-pr alternate shadow mx-auto me-md-auto ms-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        02
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 order-2 order-md-1">
                    <div className="text-center text-md-end me-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q2 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        WHAT STATS ARE INCLUDED IN A CHECKBRAND REPORT?{" "}
                      </h2>
                      <p className="text-light">
                        Checkbrand reports include a wide range of stats. From
                        the total number of social media posts sent with your
                        analyzed hashtag or keyword to the typification of those
                        posts. Posts can be classified in text, replies, links,
                        pictures and retweets. Checkbrand has developed its own
                        metric called the Brand Value. This will tell you how
                        much money you should have had to spend in order to get
                        that number of posts with those number of impressions.
                        In addition, Checkbrand also offers sentiment analytics.
                        Which is great to know how positive or negative your
                        report has been.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-3 mb-5">
                  <div className="col-md-6">
                    <div className="step-pr shadow mx-auto ms-md-auto me-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        03
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center text-md-start ms-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q3 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        HOW DO I ANALYZE SOCIAL MEDIA POSTS WITH CHECKBRAND?{" "}
                      </h2>
                      <p className="text-light">
                        Creating reports with our tracking tool is very simple.
                        Just go to our home page and you will get a free report
                        for the last 7 days. You can add any term, hashtag or
                        keyword you need to track.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 mb-5">
                  <div className="col-md-6 order-1 order-md-2">
                    <div className="step-pr alternate shadow mx-auto me-md-auto ms-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        04
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 order-2 order-md-1">
                    <div className="text-center text-md-end me-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q4 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        CAN I ANALYZE HASHTAGS WITH CHECKBRAND?{" "}
                      </h2>
                      <p className="text-light">
                        Of course! Checkbrand is the perfect AI- automated tool
                        to monitor hashtags. After making the log in on the main
                        page, the user should only include his hashtag in the
                        search box. After a few seconds, you will get two
                        complete hashtag reports. On the one hand, a basic one
                        with the main data. On the other hand, another advanced
                        with more complete analytics. In addition to monitor
                        hashtags, Checkbrand allows you to download the analyzed
                        information in Excel format. Anything that is on social
                        media can be analyzed with Checkbrand.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-3 mb-5">
                  <div className="col-md-6">
                    <div className="step-pr shadow mx-auto ms-md-auto me-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        05
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center text-md-start ms-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q5 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        {" "}
                        <i className="fa fa-plus plus_icon" />
                        CAN I ANALYZE THE MENTIONS TO AN ACCOUNT WITH
                        CHECKBRAND?{" "}
                      </h2>
                      <p className="text-light">
                        Checkbrand reports also allows you to analyze mentions
                        to an account. This will help you to get to know the
                        ambassadors or prescriptors of your brand. Imagine the
                        potential that this information can provide for your
                        business. You will obtain a list with all the mentions
                        in an Excel file, so you can better handle the data.
                        Finally, you can also have the top influencers in order
                        to complete your information and make decisions in the
                        most appropriate way.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 mb-5">
                  <div className="col-md-6 order-1 order-md-2">
                    <div className="step-pr alternate shadow mx-auto me-md-auto ms-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        06
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 order-2 order-md-1">
                    <div className="text-center text-md-end me-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q6 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        {" "}
                        <i className="fa fa-plus plus_icon" />
                        WHY ARE THE STATS IN MY REPORT NOT UPDATING?
                      </h2>
                      <p className="text-light">
                        If you have created a FREE report with Checkbrand, the
                        results you get are in the past. Meaning that the seek
                        is done for posts that have already been sent. These
                        reports give up to 500 posts from the last 7 days. In
                        order for your report to update stats in real time, you
                        need to purchase a real time report. With this PRO
                        report you can constantly check the stats of our hashtag
                        or campaign. This is a perfect choice to follow trends,
                        campaigns or events.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-3 mb-5">
                  <div className="col-md-6">
                    <div className="step-pr shadow mx-auto ms-md-auto me-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        07
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center text-md-start ms-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q7 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        {" "}
                        <i className="fa fa-plus plus_icon" />
                        CAPTURING POSTS IN REAL TIME WITH CHECKBRAND
                      </h2>
                      <p className="text-light">
                        There are two ways of capturing posts with Checkbrand:
                        You can grab up to 30 days old posts using the 30-day
                        reports or you can capture tweets in real time as they
                        are published using a live campaign. If you own a PRO
                        plan, you can activate a live tracker by clicking the
                        dropdown menu and choosing the last option “Live
                        report”. If you don’t have a PRO plan, you can purchase
                        your live campaign on the website.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 mb-5">
                  <div className="col-md-6 order-1 order-md-2">
                    <div className="step-pr alternate shadow mx-auto me-md-auto ms-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        08
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 order-2 order-md-1">
                    <div className="text-center text-md-end me-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q8 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        HOW CAN I ACCESS OLD POSTS WITH CHECKBRAND?
                      </h2>
                      <p className="text-light">
                        The social listening platform Checkbrand allows you to
                        analyze old posts easily. Actually, thanks to the
                        advanced search commands any post can be found and
                        analyzed. The Historical reports offered by Checkbrand
                        are the best option to analyze and obtain data from the
                        past. These reports can be found on the home page
                        section dedicated to the reports.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-3 mb-5">
                  <div className="col-md-6">
                    <div className="step-pr shadow mx-auto ms-md-auto me-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        09
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center text-md-start ms-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q9 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        WHAT IS THE ECONOMIC VALUE I SEE IN MY CHECKBRAND
                        REPORT?
                      </h2>
                      <p className="text-light">
                        The economic value represents the amount of money you
                        should have invested in the market in order to have the
                        posts and impressions your report has. To get to the
                        hashtag economic value, first we need to analyze each
                        one of the users in the report. Several factors are
                        taken into account such as the number of followers,
                        following or if it is a verified account or not. This is
                        a metric that can help you to have a better
                        understanding of how you campaign has evolved on
                        Twitter. You can even compare it with the real economic
                        inversion you have done for your campaign. If you want
                        to know more, in our blog you will find several posts
                        about this metric.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 mb-5">
                  <div className="col-md-6 order-1 order-md-2">
                    <div className="step-pr alternate shadow mx-auto me-md-auto ms-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        10
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 order-2 order-md-1">
                    <div className="text-center text-md-end me-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q10 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        CAN I DOWNLOAD MY REPORT AS AN EXCEL?
                      </h2>
                      <p className="text-light">
                        Yes, Checkbrand PRO allows users to export the analyzed
                        data to an Excel sheet. This way, it is easier to work
                        with the stats. The Excel sheet includes different pages
                        to check data such as: general stats, used links,
                        accounts that have shared content with the analyzed
                        hashtag as well as all the transcripts to the analyzed
                        tweets. Everything you need to analyze the data and make
                        decisions in consequence.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-3 mb-5">
                  <div className="col-md-6">
                    <div className="step-pr shadow mx-auto ms-md-auto me-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        11
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center text-md-start ms-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q11 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        IS IT POSSIBLE TO ANALYZE SOCIAL MEDIA ACCOUNTS WITH
                        CHECKBRAND?
                      </h2>
                      <p className="text-light">
                        Checkbrand allows you to analyze everything that is
                        shared on social media. What’s more, it is also a
                        perfect monitoring tool to analyze social media handles.
                        The way to get the result of an account is by searching
                        for it on the search box. In fact, we can analyze the
                        mentions that an account receives and also the posts
                        that an account sends. Therefore, the options that
                        Checkbrand offers to analyze accounts are really
                        complete.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 mb-5">
                  <div className="col-md-6 order-1 order-md-2">
                    <div className="step-pr alternate shadow mx-auto me-md-auto ms-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        12
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 order-2 order-md-1">
                    <div className="text-center text-md-end me-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q12 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        HOW TO GET FOLLOWERS WITH CHECKBRAND?
                      </h2>
                      <p className="text-light">
                        Thanks to Checkbrand you can get to know your followers
                        better. This will allow you to interact with them in a
                        more appropriate way, adapting their publications to the
                        interests of your followers. Thanks to the statistics
                        and data Checkbrand reports provide, you can optimize
                        your publications getting a greater number of followers
                        on social media. In addition, you can discover the most
                        active followers, the most retweeted or the most
                        original. With Checkbrand’s reports, you can also get to
                        know the users who post the highest number of photos,
                        the most popular ones and those who have the greatest
                        impact. You can also find out the certified users that
                        have participated and those who generate a higher number
                        of conversations.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-3 mb-5">
                  <div className="col-md-6">
                    <div className="step-pr shadow mx-auto ms-md-auto me-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        13
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center text-md-start ms-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q13 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        HOW CAN I RESTORE MY BALANCE OF POSTS?
                      </h2>
                      <p className="text-light">
                        The balance inside a Checkbrand plan is restored
                        automatically when the plan is renewed. For example, if
                        you purchased your plan on the 13th, your posts and
                        reports balance will be renewed on the 13th of the next
                        month.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 mb-5">
                  <div className="col-md-6 order-1 order-md-2">
                    <div className="step-pr alternate shadow mx-auto me-md-auto ms-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        14
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 order-2 order-md-1">
                    <div className="text-center text-md-end me-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q14 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        IS MY REPORTS BALANCE RESTORED WHEN I DELETE A REPORT?
                      </h2>
                      <p className="text-light">
                        When you delete a report, it does not add to your
                        balance again. Once the report is created and the posts
                        have been used, you can not give it back.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-3 mb-5">
                  <div className="col-md-6">
                    <div className="step-pr shadow mx-auto ms-md-auto me-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        15
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center text-md-start ms-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q15 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        THE POSTS THAT HAVE NOT BEEN USED PASS TO THE NEXT
                        MONTH'S BALANCE?
                      </h2>
                      <p className="text-light">
                        The balance of posts is restored when a plan is renewed.
                        So, if you do not use all your posts from a month, they
                        do not pass onto the next month’s balance.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 mb-5">
                  <div className="col-md-6 order-1 order-md-2">
                    <div className="step-pr alternate shadow mx-auto me-md-auto ms-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        16
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 order-2 order-md-1">
                    <div className="text-center text-md-end me-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q16 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        HOW CAN I CONTACT MY CHECKBRAND AGENT?
                      </h2>
                      <p className="text-light">
                        If you do not have his/her direct e-mail, you can
                        contact the support team by sending an email to{" "}
                        <a
                          href="/cdn-cgi/l/email-protection"
                          className="__cf_email__"
                          data-cfemail="81e8efe7eec1e2e9e4e2eae3f3e0efe5afeeefede8efe4"
                        >
                          [email&nbsp;protected]
                        </a>
                        with your request.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-3 mb-5">
                  <div className="col-md-6">
                    <div className="step-pr shadow mx-auto ms-md-auto me-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        17
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center text-md-start ms-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q17 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        IF MY SOCIAL MEDIA ACCOUNT CHANGES, CAN I CHANGE MY
                        PLAN?
                      </h2>
                      <p className="text-light">
                        Yes, just contact your Checkbrand agent and they will
                        switch your plan’s associated account.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 mb-5">
                  <div className="col-md-6 order-1 order-md-2">
                    <div className="step-pr alternate shadow mx-auto me-md-auto ms-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        18
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 order-2 order-md-1">
                    <div className="text-center text-md-end me-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q18 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        HOW CAN I GET A HISTORICAL REPORT?{" "}
                      </h2>
                      <p className="text-light">
                        This report allows you to monitor any post from the past
                        from any time back. In order to get old posts you need
                        to: first purchase it and second, you need to include
                        the search terms and the date range you want to analyze.
                        After finalizing the process, you just have to wait for
                        posts to enter the report. Once the report has finished
                        you will see it in your dashboard.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-3 mb-5">
                  <div className="col-md-6">
                    <div className="step-pr shadow mx-auto ms-md-auto me-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        19
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center text-md-start ms-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q19 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        CAN I ANALYZE DIFFERENT ACCOUNTS OR CAMPAIGNS FROM
                        DIFFERENT CLIENTS WITH MY SUBSCRIPTION?
                      </h2>
                      <p className="text-light">
                        Yes, if that is something you need. The account gets
                        assigned the plan and with it the owner of the account
                        can use the posts and report as needed. It is not needed
                        to just analyze that account, you can analyze your
                        clients and competitors as well.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 mb-5">
                  <div className="col-md-6 order-1 order-md-2">
                    <div className="step-pr alternate shadow mx-auto me-md-auto ms-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        20
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 order-2 order-md-1">
                    <div className="text-center text-md-end me-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q20 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        WHICH TWITTER OR INSTAGRAM CONTENT AM I ABLE TO INSERT
                        IN MY WEBSITE?{" "}
                      </h2>
                      <p className="text-light">
                        Checkbrand has complete access to social media data. You
                        have the power to decide what you want to show. You can
                        opt for showing just pictures or only statistics. Thanks
                        to our technology, we can insert any publication or data
                        requested and, separately, we can provide complete data.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-3 mb-5">
                  <div className="col-md-6">
                    <div className="step-pr shadow mx-auto ms-md-auto me-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        21
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center text-md-start ms-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q21 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        DO I HAVE TO BE AT THE VENUE OF THE EVENT IF I WANT TO
                        MODERATE THE SOCIAL WALL CONTENT?
                      </h2>
                      <p className="text-light">
                        No, the content curation of the Social Wall can be done
                        remotely. In fact, it can be performed from different
                        computers or mobile devices. From anywhere. It is an
                        easy and agile system to use.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 mb-5">
                  <div className="col-md-6 order-1 order-md-2">
                    <div className="step-pr alternate shadow mx-auto me-md-auto ms-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        22
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 order-2 order-md-1">
                    <div className="text-center text-md-end me-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q22 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        CAN I EXPORT MY REPORT TO PDF?
                      </h2>
                      <p className="text-light">
                        Yes, PDF exportation is a PRO feature. If you are an
                        owner of a plan or you have purchased a report, you can
                        export your stats. Exporting data to PDF is very simple.
                        You just need to go to the report and click on the PDF
                        icon in the upper right corner.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-3 mb-5">
                  <div className="col-md-6">
                    <div className="step-pr shadow mx-auto ms-md-auto me-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        23
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center text-md-start ms-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q23 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        DOES CHECKBRAND GIVE TWITTER SENTIMENT ANALYTICS??
                      </h2>
                      <p className="text-light">
                        Yes, this is also a PRO feature. In the reports
                        dashboard you can find a tab named Sentiment. There you
                        will see the sentiment score of the report. In order to
                        calculate how positive or negative your report is,
                        Checkbrand takes in consideration the impact generated
                        by each user. So, it is more negative to have a user of
                        1,000,000 followers speaking bad about your event than
                        having 10 users of 100 followers saying bad things about
                        your event.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 mb-5">
                  <div className="col-md-6 order-1 order-md-2">
                    <div className="step-pr alternate shadow mx-auto me-md-auto ms-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        24
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 order-2 order-md-1">
                    <div className="text-center text-md-end me-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q24 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        WHAT HAPPENS IF I RUN OUT OF POSTS?
                      </h2>
                      <p className="text-light">
                        First, you don’t have to worry. If your report or plan
                        runs out of posts you can always purchase extra posts.
                        Just contact the support team and they will help you.
                        Their email is{" "}
                        <a
                          href="/cdn-cgi/l/email-protection"
                          className="__cf_email__"
                          data-cfemail="90f9fef6ffd0f3f8f5f3fbf2e2f1fef4befffefcf9fef5be"
                        >
                          [email&nbsp;protected]
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-3 mb-5">
                  <div className="col-md-6">
                    <div className="step-pr shadow mx-auto ms-md-auto me-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        25
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center text-md-start ms-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q25 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        HOW CAN I CREATE A SOCIAL MEDIA WALL?
                      </h2>
                      <p className="text-light">
                        Social Media walls are created by the Checkbrand team.
                        The best thing about this service is that you don’t have
                        to worry about anything. Just send the image of your
                        event and the size of the screen where the wall will be
                        displayed and the Checkbrand team will take care of the
                        rest.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 mb-5">
                  <div className="col-md-6 order-1 order-md-2">
                    <div className="step-pr alternate shadow mx-auto me-md-auto ms-md-5">
                      <div className="text-14 fw-600 text-primary me-n2">
                        26
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 order-2 order-md-1">
                    <div className="text-center text-md-end me-md-5 faq_content">
                      <p className="text-4 fw-500 d-inline-flex align-items-center mb-2">
                        Q26 - 2022
                      </p>
                      <h2 className="text-white fw-600 mb-3 heading">
                        <i className="fa fa-plus plus_icon" />
                        HOW CAN I CREATE A FREE SOCIAL MEDIA REPORT?
                      </h2>
                      <p className="text-light">
                        Just go to our homepage and type the hashtag, term,
                        account or keyword that you need to track. The free
                        report will give you up to 500 posts from the last 7
                        days.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
