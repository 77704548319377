import { call, put, takeEvery } from 'redux-saga/effects';
import {categorySuccess,categoryError} from '../actions/brand_categories.action';
import { getBrandCategoriesApi } from '../services/api.service';
import { BRAND_CATEGORIES } from '../types';

function* fetchBrandCategory() {
    try {
        const categories = yield call(getBrandCategoriesApi);
        yield put(categorySuccess(categories));
      } catch (e) {
        yield put(categoryError(e));
      }
}

export default function* brandCategorySaga() {
    yield takeEvery(BRAND_CATEGORIES.FETCH, fetchBrandCategory)
}